import React from 'react';
import { FaPhone } from 'react-icons/fa';
import '../Styles/telefonKnap.css';

const TelefonKnap = () => {
  return (
    <a href="tel:36170000" className="telefon-knap">
      <FaPhone className="telefon-ikon" />
      <span className="telefon-nummer">36 17 00 00</span>
    </a>
  );
};

export default TelefonKnap;
