import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/GoogleAds.css';
import CTAButton from '../Components/CTAButton';
import InvertCTAButton from '../Components/InvertCTAButton';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence } from 'framer-motion';
import CustomerTestimonials from '../Components/CustomerTestimonials';
import CustomerLogos from '../Components/CustomerLogos';
import FAQ from '../Components/FAQ';
import CasesBlack from '../Components/CasesBlack';

function GoogleAds() {
  const headerBackgroundRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });
  const [searchText, setSearchText] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [visibleResults, setVisibleResults] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      if (headerBackgroundRef.current) {
        headerBackgroundRef.current.style.transform = `translateY(${scrollPosition * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const text = "Din virksomhed";
    let index = 0;

    const typingInterval = setInterval(() => {
      if (index < text.length) {
        setSearchText(prev => text.slice(0, index + 1));
        index++;
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          setShowResults(true);
          const resultInterval = setInterval(() => {
            setVisibleResults(prev => {
              if (prev < 3) return prev + 1;
              clearInterval(resultInterval);
              return prev;
            });
          }, 500);
        }, 500);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, []);

  useEffect(() => {
    if (inView) {
      const animatedNumbers = document.querySelectorAll('.animated-number');
      animatedNumbers.forEach(el => {
        const target = parseInt(el.getAttribute('data-target'));
        let current = 0;
        const increment = target / 100;
        const timer = setInterval(() => {
          current += increment;
          el.textContent = Math.round(current);
          if (current >= target) {
            el.textContent = target;
            clearInterval(timer);
          }
        }, 20);
      });
    }
  }, [inView]);

  const resultVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  return (
    <div className="googleads-page">
      <section className="googleads-header">
        <div className="googleads-header-background" ref={headerBackgroundRef}></div>
        <h1>Google Ads</h1>
      </section>

      <section className="fordele" ref={ref}>
        <div className="fordele">
          <h2>Hvorfor skal du bruge Google Ads?</h2>
          <p>Google Ads er en afgørende strategi for virksomheder, der ønsker hurtig og målrettet synlighed online. Med Google Ads kan du nå potentielle kunder præcis, når de søger efter de produkter eller tjenester, du tilbyder. Dette gør det muligt at generere trafik til din hjemmeside, øge konverteringer og maksimere din investering. Google Ads giver dig også fuld kontrol over dit budget og mulighed for at måle resultater i realtid.</p>
        </div>
        <div className="fordele-grid">
          <div className="fordel-item">
            <div className="fordel-icon">
              <i className="fas fa-bullseye"></i>
            </div>
            <h3><span className="animated-number" data-target="90">0</span>%</h3>
            <p>af forbrugerne siger, at annoncer påvirker deres købsbeslutninger</p>
          </div>
          <div className="fordel-item">
            <div className="fordel-icon">
              <i className="fas fa-chart-line"></i>
            </div>
            <h3><span className="animated-number" data-target="200">0</span>%</h3>
            <p>gennemsnitlig ROI for Google Ads-kampagner</p>
          </div>
          <div className="fordel-item">
            <div className="fordel-icon">
              <i className="fas fa-mobile-alt"></i>
            </div>
            <h3><span className="animated-number" data-target="62">0</span>%</h3>
            <p>af alle klik på Google Ads kommer fra mobile enheder</p>
          </div>
          <div className="fordel-item">
            <div className="fordel-icon">
              <i className="fas fa-search-dollar"></i>
            </div>
            <h3><span className="animated-number" data-target="65">0</span>%</h3>
            <p>af forbrugere klikker på betalte annoncer, når de er klar til at købe</p>
          </div>
        </div>
      </section>

      <section className="googleads-hero">
        <div className="googleads-hero-content">
          <h2>Få din virksomhed frem med Google Ads</h2>
          <p className="googleads-hero-text">Med Google Ads kan din virksomhed blive synlig øverst i relevante Google-søgninger og nå kunder, der aktivt søger efter dine produkter eller tjenester. Det hjælper dig med at tiltrække kvalificerede leads, øge trafikken til din hjemmeside og opnå konkrete resultater hurtigt. Vores eksperter sikrer, at dine annoncer er optimeret til at skabe maksimal værdi og levere et stærkt afkast på din investering.</p>
        <div className="proces-afsnit-cta desktop-only">
          <CTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
        </div>
        <div className="googleads-search-preview">
          <div className="search-bar">
            <input 
              type="text" 
              value={searchText} 
              readOnly 
              placeholder="Søg..." 
              className="non-interactive"
            />
            <button className="search-button non-interactive">Søg</button>
          </div>
          <div className="search-results">
            <AnimatePresence>
              {visibleResults > 0 && (
                <motion.div
                  className="search-result top-result"
                  variants={resultVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <h3>Google Ads Annonce</h3>
                  <p className="result-url">www.dinvirksomhed.dk</p>
                  <p className="result-description">Professionel Google Ads-hjælp. Øg synlighed og ROI nu!</p>
                </motion.div>
              )}
              {visibleResults > 1 && (
                <motion.div
                  className="search-result placeholder-result"
                  variants={resultVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <div className="placeholder-line"></div>
                  <div className="placeholder-line"></div>
                </motion.div>
              )}
              {visibleResults > 2 && (
                <motion.div
                  className="search-result placeholder-result"
                  variants={resultVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <div className="placeholder-line"></div>
                  <div className="placeholder-line"></div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only-seo">
          <CTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section>
        <CustomerLogos />
      </section>

      <section className="proces-afsnit">
        <div className="proces-container">
          <div className="proces-title">
            <h2>Vores proces for Google Ads</h2>
            <p>Vores proces for Google Ads-kampagner omfatter dybdegående analyse af målgruppe og marked, strategisk opsætning med nøje udvalgte søgeord og målrettede annoncer, løbende optimering for maksimal performance samt detaljeret rapportering, der giver indsigt i resultaterne. Vi sikrer, at dine kampagner genererer kvalificeret trafik, øger konverteringer og leverer målbare forretningsresultater.</p>
            <div className="proces-afsnit-cta desktop-only">
              <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
            </div>
          </div>
          <div className="proces-trin">
            <div className="trin">
              <h3>1. Kampagneanalyse</h3>
              <p>Vi gennemfører en grundig analyse af dine nuværende kampagner, målgrupper og nøgleord.</p>
            </div>
            <div className="trin">
              <h3>2. Strategi og målretning</h3>
              <p>Vi udvikler en skræddersyet strategi og målretter dine annoncer til de mest relevante søgninger og målgrupper.</p>
            </div>
            <div className="trin">
              <h3>3. Annonceoptimering</h3>
              <p>Vi optimerer dine annoncer for at maksimere klikrater og konverteringer.</p>
            </div>
            <div className="trin">
              <h3>4. Løbende optimering</h3>
              <p>Vi overvåger og justerer løbende dine kampagner for at sikre de bedste resultater og laveste omkostninger.</p>
            </div>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only">
          <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section className="kundeafsnit">
        <CustomerTestimonials />
      </section>

      <CasesBlack />

      <section className="ekstra-services">
        <div className="ekstra-services-content">
          <h2>Få mere ud af din online markedsføring</h2>
          <p>I forbindelse med opsætningen af dine Google Ads-kampagner tilbyder vi også tjenester såsom SEO og AI-chatbots. Disse løsninger arbejder sammen for at styrke din virksomheds online tilstedeværelse, tiltrække relevante kunder og forbedre dine resultater. Vi sikrer, at din digitale strategi er helhedsorienteret, effektiv og tilpasset netop dine behov:</p>
        </div>
        <div className="services-grid">
          <Link to="/webdesign" className="service-item">
            <div className="service-icon">
              <i className="fas fa-laptop-code"></i>
            </div>
            <div className="service-text">
              <h3>Webdesign</h3>
              <p>Skræddersyede hjemmesider, der konverterer besøgende til kunder</p>
            </div>
          </Link>
          <Link to="/seo" className="service-item">
            <div className="service-icon">
              <i className="fas fa-search"></i>
            </div>
            <div className="service-text">
              <h3>SEO-optimering</h3>
              <p>Vi hjælper din virksomhed med at forbedre dens placering i søgeresultaterne gennem målrettet søgemaskineoptimering.</p>
            </div>
          </Link>
          <Link to="/ai-chatbot" className="service-item">
            <div className="service-icon">
              <i className="fas fa-robot"></i>
            </div>
            <div className="service-text">
              <h3>AI Chatbot</h3>
              <p>Intelligent kundesupport døgnet rundt med automatisering</p>
            </div>
          </Link>
        </div>
      </section>

      <section className="faq-section">
        <FAQ />
      </section>
    </div>
  );
}

export default GoogleAds;