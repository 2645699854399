import React, { useState, useEffect, useRef } from 'react';
import '../Styles/AIChatbot.css';
import CTAButton from '../Components/CTAButton';
import { useInView } from 'react-intersection-observer';
import CustomerLogos from '../Components/CustomerLogos';
import InvertCTAButton from '../Components/InvertCTAButton';
import FAQ from '../Components/FAQ';
import { Link } from 'react-router-dom';
import CustomerTestimonials from '../Components/CustomerTestimonials';
import CasesBlack from '../Components/CasesBlack';

function AIChatbot() {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [currentSender, setCurrentSender] = useState('bruger');
  const headerBackgroundRef = useRef(null);

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  useEffect(() => {
    const simulateConversation = async () => {
      while (true) {
        // Bruger skriver
        setCurrentSender('bruger');
        setIsTyping(true);
        await delay(2000);
        setIsTyping(false);
        await addMessage("bruger");
        await delay(1000);

        // AI skriver
        setCurrentSender('ai');
        setIsTyping(true);
        await delay(2000);
        setIsTyping(false);
        await addMessage("ai");
        await delay(1000);
      }
    };

    simulateConversation();

    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      if (headerBackgroundRef.current) {
        headerBackgroundRef.current.style.transform = `translateY(${scrollPosition * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (inView) {
      const animatedNumbers = document.querySelectorAll('.animated-number');
      animatedNumbers.forEach(el => {
        const target = parseInt(el.textContent);
        let current = 0;
        const increment = target / 100;
        const timer = setInterval(() => {
          current += increment;
          el.textContent = Math.round(current);
          if (current >= target) {
            el.textContent = target;
            clearInterval(timer);
          }
        }, 20);
      });
    }
  }, [inView]);

  const addMessage = async (sender) => {
    setMessages(prevMessages => {
      // Fjern den sidste besked, hvis den er fra samme afsender
      const newMessages = prevMessages.length > 0 && prevMessages[prevMessages.length - 1].sender === sender
        ? prevMessages.slice(0, -1)
        : prevMessages;
      return [...newMessages, {sender, id: Date.now()}];
    });
    await delay(500);
  };

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  return (
    <div className="ai-chatbot-page">
      <section className="ai-chatbot-header">
        <div className="ai-chatbot-header-background" ref={headerBackgroundRef}></div>
        <h1>AI Chatbot</h1>
      </section>

      <section className="fordele" ref={ref}>
        <div className="fordele">
          <h2>Fordele ved at Implementere en Chatbot</h2>
          <p>En AI-drevet chatbot kan transformere din hjemmeside ved at forbedre kundeservice med øjeblikkelig assistance døgnet rundt. Den hjælper med at øge konverteringer ved at guide besøgende gennem købsprocessen og sparer dig værdifuld tid ved at automatisere rutineopgaver.</p>
        </div>
        <div className="fordele-grid">
          <div className="fordel-item">
            <div className="fordel-icon">
              <svg viewBox="0 0 24 24" width="48" height="48">
                <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
              </svg>
            </div>
            <h3><span className="animated-number">73</span>%</h3>
            <p>af brugere forventer, at hjemmesider har chatbots til nemme interaktioner</p>
          </div>
          <div className="fordel-item">
            <div className="fordel-icon">
              <svg viewBox="0 0 24 24" width="48" height="48">
                <path d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 4.74 14.12 4 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/>
              </svg>
            </div>
            <h3><span className="animated-number">90</span>%</h3>
            <p>af virksomheder oplevede hurtigere klageløsning takket være chatbots</p>
          </div>
          <div className="fordel-item">
            <div className="fordel-icon">
              <svg viewBox="0 0 24 24" width="48" height="48">
                <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"/>
              </svg>
            </div>
            <h3><span className="animated-number">2,5</span> mia.</h3>
            <p>arbejdstimer forventes virksomheder at have sparet inden udgangen af 2023</p>
          </div>
          <div className="fordel-item">
            <div className="fordel-icon">
              <svg viewBox="0 0 24 24" width="48" height="48">
                <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z"/>
              </svg>
            </div>
            <h3><span className="animated-number">80</span>%</h3>
            <p>af simple brugerforespørgsler kan håndteres problemfrit af et veldesignet chatbot-system</p>
          </div>
        </div>
      </section>

<section className="ai-chatbot-hero">
  <div className="ai-chatbot-hero-content">
    <h1>Revolutioner din kundeservice med AI Chatbot</h1>
    <p>Giv dine besøgende øjeblikkelig support døgnet rundt med vores avancerede AI-drevne chatbot. Boost kundetilfredsheden, optimer din arbejdsgang og øg dine konverteringer med intelligent automatisering.</p>
    <div className="proces-afsnit-cta desktop-only">
      <CTAButton text="Kom i gang" navigateTo="/kontakt" />
    </div>
  </div>
  <div className="chat-window">
    <div className="chat-messages">
      {messages.map((message) => (
        <div key={message.id} className={`message ${message.sender}`}>
          <div className="message-icon">
            {message.sender === 'bruger' ? 
              <svg viewBox="0 0 24 24" width="24" height="24">
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
              </svg> : 
              <svg viewBox="0 0 24 24" width="24" height="24">
                <path d="M20 9V7c0-1.1-.9-2-2-2h-3c0-1.66-1.34-3-3-3S9 3.34 9 5H6c-1.1 0-2 .9-2 2v2c-1.66 0-3 1.34-3 3s1.34 3 3 3v4c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-4c1.66 0 3-1.34 3-3s-1.34-3-3-3zM7.5 11.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5S9.83 13 9 13s-1.5-.67-1.5-1.5zM16 17H8v-2h8v2zm-1-4c-.83 0-1.5-.67-1.5-1.5S14.17 10 15 10s1.5.67 1.5 1.5S15.83 13 15 13z" />
              </svg>
            }
          </div>
          <div className="message-content"></div>
        </div>
      ))}
      {isTyping && (
        <div className={`message ${currentSender}`}>
          <div className="message-icon">
            {currentSender === 'bruger' ? 
              <svg viewBox="0 0 24 24" width="24" height="24">
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
              </svg> : 
              <svg viewBox="0 0 24 24" width="24" height="24">
                <path d="M20 9V7c0-1.1-.9-2-2-2h-3c0-1.66-1.34-3-3-3S9 3.34 9 5H6c-1.1 0-2 .9-2 2v2c-1.66 0-3 1.34-3 3s1.34 3 3 3v4c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-4c1.66 0 3-1.34 3-3s-1.34-3-3-3zM7.5 11.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5S9.83 13 9 13s-1.5-.67-1.5-1.5zM16 17H8v-2h8v2zm-1-4c-.83 0-1.5-.67-1.5-1.5S14.17 10 15 10s1.5.67 1.5 1.5S15.83 13 15 13z" />
              </svg>
            }
          </div>
          <div className="typing-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      )}
    </div>
  </div>
  <div className="proces-afsnit-cta mobile-tablet-only">
    <CTAButton text="Kontakt os" navigateTo="/kontakt" />
  </div>
</section>

      <section>
        <CustomerLogos />
      </section>

      <section className="proces-afsnit">
        <div className="proces-container">
          <div className="proces-title">
            <h2>Sådan udvikler vi din AI-Chatbot</h2>
            <p>Vi udvikler en chatbot, der er skræddersyet og unik til netop din hjemmeside. Med fokus på brugervenlighed og funktionalitet hjælper vi dig med at forbedre kundeservice, engagere besøgende og skabe en mere effektiv digital oplevelse.</p>
            <div className="proces-afsnit-cta desktop-only">
              <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
            </div>
          </div>
          <div className="proces-trin">
            <div className="trin">
              <h3>1. Behovsanalyse</h3>
              <p>Vi analyserer din virksomheds behov og mål for at skræddersy chatbotten til dine specifikke krav.</p>
            </div>
            <div className="trin">
              <h3>2. Dataindsamling og træning</h3>
              <p>Vi indsamler relevante data og træner chatbotten ved hjælp af OpenAI's avancerede API for at sikre præcise og kontekstuelle svar.</p>
            </div>
            <div className="trin">
              <h3>3. Integration og tilpasning</h3>
              <p>Vi integrerer chatbotten på din hjemmeside og tilpasser dens udseende og funktionalitet til dit brand.</p>
            </div>
            <div className="trin">
              <h3>4. Lancering og support</h3>
              <p>Vi lancerer din nye AI-chatbot og tilbyder løbende support og opdateringer for at sikre dens effektivitet over tid.</p>
            </div>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only">
          <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section className="kundeafsnit">
        <CustomerTestimonials />
      </section>

      <CasesBlack />

      <section className="ekstra-services">
        <div className="ekstra-services-content">
          <h2>Få mere ud af din hjemmeside</h2>
          <p>I takt med at vi integrerer din AI-chatbot på din hjemmeside, tilbyder vi også tjenester såsom SEO og Google Ads. Disse løsninger hjælper med at øge din virksomheds online synlighed, tiltrække flere relevante kunder og skabe bedre resultater.</p>
        </div>
        <div className="services-grid">
          <Link to="/webdesign" className="service-item">
            <div className="service-icon">
              <i className="fas fa-laptop-code"></i>
            </div>
            <div className="service-text">
              <h3>Webdesign</h3>
              <p>Skræddersyede hjemmesider der omsætter besøgende til kunder og styrker din brand identitet</p>
            </div>
          </Link>
          <Link to="/seo" className="service-item">
            <div className="service-icon">
              <i className="fas fa-search"></i>
            </div>
            <div className="service-text">
              <h3>SEO-optimering</h3>
              <p>Vi hjælper din virksomhed med at forbedre dens placering i søgeresultaterne gennem målrettet søgemaskineoptimering.</p>
            </div>
          </Link>
          <Link to="/google-ads" className="service-item">
            <div className="service-icon">
              <i className="fab fa-google"></i>
            </div>
            <div className="service-text">
              <h3>Google Ads-kampagner</h3>
              <p>Tiltræk kunder til din virksomhed gennem Google Ads. Vi sørger for, at dine annoncer når ud til de rigtige kunder og bidrager til vækst og resultater.</p>
            </div>
          </Link>
        </div>
      </section>

      <section className="faq-section">
        <FAQ />
      </section>
    </div>
  );
}

export default AIChatbot;
