import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CTAButton from '../Components/CTAButton';
import InvertCTAButton from '../Components/InvertCTAButton';
import CasesSlideshow from '../Components/CasesSlideshow';
import CustomerTestimonials from '../Components/CustomerTestimonials';
import CustomerLogos from '../Components/CustomerLogos';
import FAQ from '../Components/FAQ';
import '../Styles/Forside.css';
import CasesBlack from '../Components/CasesBlack';

function Forside() {
  const heroBackgroundRef = useRef(null);
  const animatedTextRef = useRef(null);

  const [animatedText, setAnimatedText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      if (heroBackgroundRef.current) {
        heroBackgroundRef.current.style.transform = `translateY(${scrollPosition * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const texts = ['Tilfredshedsgaranti','100% responsiv', 'Kodet fra bunden', 'SEO-optimeret', 'Hurtig og effektiv'];
    let currentIndex = 0;
    let currentText = '';
    let isDeleting = false;
    
    function typeText() {
      const fullText = texts[currentIndex];
      
      if (isDeleting) {
        currentText = fullText.substring(0, currentText.length - 1);
      } else {
        currentText = fullText.substring(0, currentText.length + 1);
      }

      setAnimatedText(currentText);
      setShowCursor(true);

      let typingSpeed = isDeleting ? 50 : 100;

      if (!isDeleting && currentText === fullText) {
        typingSpeed = 2000;
        isDeleting = true;
        setShowCursor(false);
      } else if (isDeleting && currentText === '') {
        isDeleting = false;
        currentIndex = (currentIndex + 1) % texts.length;
        typingSpeed = 500;
        setShowCursor(false);
      }

      setTimeout(typeText, typingSpeed);
    }

    typeText();
  }, []);

  return (
    <div className="forside">
      <section className="hero">
        <div className="hero-background">
          <video autoPlay muted loop playsInline className="hero-video">
            <source src={require('../images/video/hero-video1.mp4')} type="video/mp4" />
          </video>
        </div>
        <div className="hero-content">
          <h1>Hjemmesider med fokus på din virksomhed</h1>
          <p>Din hjemmeside skal afspejle din virksomheds værdier. Vi leverer webdesign, der styrker dit brand og skaber tillid hos besøgende.</p>
          <CTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <CasesSlideshow />

      <section className="fordele">
        <h2>Hvorfor vælge os?</h2>
        <p>Vi leverer ikke bare hjemmesider, men helhedsorienterede løsninger, der er skabt med tanke på din forretning. Vores tilgang sikrer, at du får en digital platform, der fremmer din synlighed og hjælper dig med at nå ud til dine kunder på en effektiv måde.</p>
        <div className="fordele-grid">
          <div className="special-fordel-item">
            <i className="fas fa-eye"></i>
            <h3>Se før du køber</h3>
            <p>Vi laver et udkast til din hjemmeside inden du betaler for at sikre, at det lever op til dine forventninger.</p>
          </div>
          <div className="fordel-item">
            <i className="fas fa-unlock"></i>
            <h3>Ingen binding</h3>
            <p>Du har friheden til at træffe beslutninger, der passer til din virksomhed, fordi vi tror på kvaliteten af det, vi leverer.</p>
          </div>
          <div className="fordel-item">
            <i className="fas fa-mobile-alt"></i>
            <h3>100% responsiv</h3>
            <p>Din hjemmeside designes til at se godt ud på alle skærmstørrelser. Vi skaber en responsiv oplevelse på alle enheder.</p>
          </div>
          <div className="fordel-item">
            <i className="fas fa-cookie-bite"></i>
            <h3>Cookiebanner</h3>
            <p>Vi implementerer et brugervenligt cookiebanner, der informerer besøgende om brugen af cookies på siden.</p>
          </div>
        </div>
      </section>

      <section className="professionelt-webdesign">
        <div className="professionelt-webdesign-container">
          <div className="professionelt-webdesign-content">
            <h2>Professionelt webdesign kodet fra bunden</h2>
            <p>Med vores tilgang til webdesign skaber vi hjemmesider, der er visuelt tiltalende og teknisk solide. Vores designprocess inkluderer kodning fra bunden, hvilket betyder, at hver løsning er unik og tilpasset dine behov. Vi sikrer, at din hjemmeside ser fantastisk ud og fungerer effektivt på alle enheder.</p>
            <CTAButton text="Kontakt os" navigateTo="/kontakt" />
          </div>
          <div className="professionelt-webdesign-visual">
            <div className="code-block"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <pre>
                <code>
                  <span className="code-content" dangerouslySetInnerHTML={{
                    __html: `&lt;div class="unique-design">
  &lt;header>
    &lt;nav>
      &lt;ul>
        &lt;li>&lt;a href="#home">Hjem&lt;/a>&lt;/li>
        li><span class="animated-text">${animatedText}</span><span class="cursor">${showCursor ? '|' : ''}</span>&lt;span class="cursor">${showCursor ? '|' : ''}&lt;/span>&lt;/li>
        &lt;li>&lt;a href="#contact">Kontakt&lt;/a>&lt;/li>
      &lt;/ul>
    &lt;/nav>
  &lt;/header>
  &lt;main>
    &lt;h1>Din Unikke Hjemmeside&lt;/h1>
    &lt;p>Skræddersyet til dine behov&lt;/p>
  &lt;/main>
&lt;/div>`
                  }} />
                </code>
              </pre>
            </div>
            {window.innerWidth <= 768 && (
              <CTAButton className="code-block-mobile-cta-button" text="Kontakt os" navigateTo="/kontakt" />
            )}
          </div>
        </div>
        <CustomerLogos />
      </section>

      <section className="proces-afsnit">
        <div className="proces-container">
          <div className="proces-title">
            <h2>Sådan skaber vi din hjemmeside</h2>
            <p>Hos Parla stræber vi altid efter et tæt og engageret samarbejde, der bygger på gensidig tillid og forståelse. Vi samarbejder tæt med dig gennem hele forløbet for at skabe en hjemmeside, der ikke bare ser fantastisk ud, men også performer optimalt.</p>
            <div className="proces-afsnit-cta desktop-only">
              <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
            </div>
          </div>
          <div className="proces-trin">
            <div className="trin">
              <h3>1. Indledende møde</h3>
              <p>Vi holder et møde, hvor vi sammen afdækker dine behov som virksomhed og udforsker dine ønsker og visioner for projektet.</p>
            </div>
            <div className="trin">
              <h3>2. Tilbud</h3>
              <p>Vi udarbejder et tilbud baseret på dine krav, og ved accept påbegynder vi arbejdet.</p>
            </div>
            <div className="trin">
              <h3>3. Design</h3>
              <p>Vi designer og udvikler din hjemmeside, og du kan følge med og give feedback undervejs.</p>
            </div>
            <div className="trin">
              <h3>4. Lancering</h3>
              <p>Vi lancerer din hjemmeside og sørger for, at den bliver vist på Google.</p>
            </div>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only">
          <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section className="kundeafsnit">
        <CustomerTestimonials />
      </section>

      <CasesBlack />

      <section className="ekstra-services">
        <div className="ekstra-services-content">
          <h2>Få mere ud af din hjemmeside</h2>
          <p>Udover skræddersyet webdesign hjælper vi dig med at sikre, at din virksomhed får et solidt grundlag for synlighed og succes i den digitale verden. Udforsk vores yderligere tjenester som SEO, Google Ads og AI-chatbots for at maksimere din online tilstedeværelse:</p>
        </div>
        <div className="services-grid">
          <Link to="/seo" className="service-item">
            <div className="service-icon">
              <i className="fas fa-search"></i>
            </div>
            <div className="service-text">
              <h3>SEO-optimering</h3>
              <p>Vi hjælper din virksomhed med at forbedre dens placering i søgeresultaterne gennem målrettet søgemaskineoptimering.</p>
            </div>
          </Link>
          <Link to="/google-ads" className="service-item">
            <div className="service-icon">
              <i className="fab fa-google"></i>
            </div>
            <div className="service-text">
              <h3>Google Ads-kampagner</h3>
              <p>Tiltræk kunder til din virksomhed gennem Google Ads. Vi sørger for, at dine annoncer når ud til de rigtige kunder og bidrager til vækst og resultater.</p>
            </div>
          </Link>
          <Link to="/ai-chatbot" className="service-item">
            <div className="service-icon">
              <i className="fas fa-robot"></i>
            </div>
            <div className="service-text">
              <h3>AI Chatbot</h3>
              <p>Giv dine besøgende øjeblikkelig support døgnet rundt med intelligent automatisering</p>
            </div>
          </Link>
        </div>
      </section>

      <section className="faq-section">
        <FAQ />
      </section>
    </div>
  );
}

export default Forside;