import React, { useEffect, useRef } from 'react';
import '../Styles/Om.css';
import CTAButton from '../Components/CTAButton';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import nicolaiImage from '../images/personale/Nicolai.jpg';
import markImage from '../images/personale/Mark.jpg';
import CustomerTestimonials from '../Components/CustomerTestimonials';
import CustomerLogos from '../Components/CustomerLogos';
import FAQ from '../Components/FAQ';
import InvertCTAButton from '../Components/InvertCTAButton';
import CasesBlack from '../Components/CasesBlack';

function Om() {
  const headerBackgroundRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      if (headerBackgroundRef.current) {
        headerBackgroundRef.current.style.transform = `translateY(${scrollPosition * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="om-page">
      <section className="om-header">
        <div className="om-header-background" ref={headerBackgroundRef}></div>
        <h1>Om Parla Branding</h1>
      </section>

      <section className="fordele">
        <h2>Vores værdier</h2>
        <p>Hos Parla Branding er vores værdier fundamentet for alt, hvad vi gør. De guider vores beslutninger, former vores kultur og definerer vores tilgang til at skabe værdi for vores kunder. Vi stræber efter gennemsigtighed, kvalitet og innovation i alle aspekter af vores arbejde. Vores engagement i at lytte til vores kunder sikrer, at vi altid leverer skræddersyede løsninger, der opfylder deres unikke behov og mål.</p>
        <div className="fordele-grid">
          <div className="fordel-item">
            <i className="fas fa-check-circle"></i>
            <h3>Kvalitet</h3>
            <p>Kvalitet er kernen i alt, hvad vi gør. Vi stræber efter at levere løsninger, der ikke kun ser fantastiske ud, men også fungerer optimalt.</p>
          </div>
          <div className="fordel-item">
            <i className="fas fa-smile"></i>
            <h3>Tilfredshed</h3>
            <p>Din succes er vores succes. Vi går den ekstra mil for at sikre din tilfredshed og leverer løsninger, der opfylder dine behov og overgår dine forventninger.</p>
          </div>
          <div className="fordel-item">
            <i className="fas fa-lightbulb"></i>
            <h3>Innovation</h3>
            <p>Innovation driver os fremad. Vi er altid på forkant med den nyeste teknologi og trends i den digitale verden for at sikre, at vores løsninger er moderne og effektive. </p>
          </div>
          <div className="fordel-item">
            <i className="fas fa-shield-alt"></i>
            <h3>Integritet</h3>
            <p>Integritet er en hjørnesten hos Parla Branding. Vi vægter ærlighed og gennemsigtighed i alle vores relationer.</p>
          </div>
        </div>
      </section>

      <section className="om-hero">
        <div className="om-hero-content">
          <h1>Din Partner i Digital Forvandling</h1>
          <p>Vi forstår, at hver virksomhed er unik og kræver en skræddersyet tilgang. Vi er passionerede omkring at bringe din vision til live ved at lytte nøje til dine behov og de udfordringer, du står overfor. Vores mål er at skabe en personlig og engagerende oplevelse, hvor vi samarbejder om at udvikle digitale løsninger, der både lever op til dine forventninger og driver vækst og succes. Hos os er din rejse mod en stærkere og mere effektiv online tilstedeværelse altid vores højeste prioritet</p>
          <div className="proces-afsnit-cta desktop-only">
            <CTAButton text="Kontakt os" navigateTo="/kontakt" />
          </div>
        </div>
        <div className="om-team-members">
          <div className="om-team-member">
            <img src={nicolaiImage} alt="Nicolai Malik Jeppesen" />
            <div className="om-team-member-info">
              <h3>Nicolai Malik Jeppesen</h3>
              <p>Stifter og medejer af Parla Branding</p>
              <p>Webdesigner</p>
              <p>Mail: malik@parlabranding.dk</p>
              <p>Telefon: +45 20 100 250</p>
            </div>
          </div>
          <div className="om-team-member">
            <img src={markImage} alt="Mark Abildgaard Christensen" />
            <div className="om-team-member-info">
              <h3>Mark Abildgaard Christensen</h3>
              <p>Stifter og medejer af Parla Branding</p>
              <p>Webdesigner</p>
              <p>Mail: mark@parlabranding.dk</p>
              <p>Telefon: +45 20 100 240</p>
            </div>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only">
          <CTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section>
        <CustomerLogos />
      </section>

      <section className="proces-afsnit">
        <div className="proces-container">
          <div className="proces-title">
            <h2>Sådan skaber vi din hjemmeside</h2>
            <p>Hos Parla stræber vi altid efter et tæt og engageret samarbejde, der bygger på gensidig tillid og forståelse. Vi samarbejder tæt med dig gennem hele forløbet for at skabe en hjemmeside, der ikke bare ser fantastisk ud, men også performer optimalt.</p>
            <div className="proces-afsnit-cta desktop-only">
              <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
            </div>
          </div>
          <div className="proces-trin">
            <div className="trin">
              <h3>1. Indledende møde</h3>
              <p>Vi holder et møde, hvor vi sammen afdækker dine behov som virksomhed og udforsker dine ønsker og visioner for projektet.</p>
            </div>
            <div className="trin">
              <h3>2. Tilbud</h3>
              <p>Vi udarbejder et tilbud baseret på dine krav, og ved accept påbegynder vi arbejdet.</p>
            </div>
            <div className="trin">
              <h3>3. Design</h3>
              <p>Vi designer og udvikler din hjemmeside, og du kan følge med og give feedback undervejs.</p>
            </div>
            <div className="trin">
              <h3>4. Lancering</h3>
              <p>Vi lancerer din hjemmeside og sørger for, at den bliver vist på Google.</p>
            </div>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only">
          <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section className="kundeafsnit">
        <CustomerTestimonials />
      </section>

      <CasesBlack />

      <section className="ekstra-services">
        <div className="ekstra-services-content">
          <h2>Få mere ud af din hjemmeside</h2>
          <p>Vi hjælper dig med at sikre, at din virksomhed får et solidt grundlag for succes i den digitale verden. Udforsk vores tjenester udover webdesign, såsom SEO, Google Ads og AI-chatbots, for at styrke din virksomheds online tilstedeværelse:</p>
        </div>
        <div className="services-grid">
          <Link to="/webdesign" className="service-item">
            <div className="service-icon">
              <i className="fas fa-laptop-code"></i>
            </div>
            <div className="service-text">
              <h3>Webdesign</h3>
              <p>Skræddersyede hjemmesider der omsætter besøgende til kunder og styrker din brand identitet</p>
            </div>
          </Link>
          <Link to="/seo" className="service-item">
            <div className="service-icon">
              <i className="fas fa-search"></i>
            </div>
            <div className="service-text">
              <h3>SEO-optimering</h3>
              <p>Vi hjælper din virksomhed med at forbedre dens placering i søgeresultaterne gennem målrettet søgemaskineoptimering.</p>
            </div>
          </Link>
          <Link to="/google-ads" className="service-item">
            <div className="service-icon">
              <i className="fab fa-google"></i>
            </div>
            <div className="service-text">
              <h3>Google Ads-kampagner</h3>
              <p>Tiltræk kunder til din virksomhed gennem Google Ads. Vi sørger for, at dine annoncer når ud til de rigtige kunder og bidrager til vækst og resultater.</p>
            </div>
          </Link>
          <Link to="/ai-chatbot" className="service-item">
            <div className="service-icon">
              <i className="fas fa-robot"></i>
            </div>
            <div className="service-text">
              <h3>AI Chatbot</h3>
              <p>Giv dine besøgende øjeblikkelig support døgnet rundt med intelligent automatisering</p>
            </div>
          </Link>
        </div>
      </section>

      <section className="faq-section">
        <FAQ />
      </section>
    </div>
  );
}

export default Om;