import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/SEO.css';
import CTAButton from '../Components/CTAButton';
import InvertCTAButton from '../Components/InvertCTAButton';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence } from 'framer-motion';
import CustomerTestimonials from '../Components/CustomerTestimonials';
import CustomerLogos from '../Components/CustomerLogos';
import FAQ from '../Components/FAQ';
import CasesBlack from '../Components/CasesBlack';

function SEO() {
  const headerBackgroundRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });
  const [searchText, setSearchText] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [visibleResults, setVisibleResults] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      if (headerBackgroundRef.current) {
        headerBackgroundRef.current.style.transform = `translateY(${scrollPosition * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const text = "Din virksomhed";
    let index = 0;

    const typingInterval = setInterval(() => {
      if (index < text.length) {
        setSearchText(prev => text.slice(0, index + 1));
        index++;
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          setShowResults(true);
          const resultInterval = setInterval(() => {
            setVisibleResults(prev => {
              if (prev < 3) return prev + 1;
              clearInterval(resultInterval);
              return prev;
            });
          }, 500);
        }, 500);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, []);

  useEffect(() => {
    if (inView) {
      const animatedNumbers = document.querySelectorAll('.animated-number');
      animatedNumbers.forEach(el => {
        const target = parseInt(el.getAttribute('data-target'));
        let current = 0;
        const increment = target / 100;
        const timer = setInterval(() => {
          current += increment;
          el.textContent = Math.round(current);
          if (current >= target) {
            el.textContent = target;
            clearInterval(timer);
          }
        }, 20);
      });
    }
  }, [inView]);

  const resultVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  return (
    <div className="googleads-page">
      <section className="googleads-header">
        <div className="seo-header-background" ref={headerBackgroundRef}></div>
        <h1>SEO-optimering</h1>
      </section>

      <section className="fordele" ref={ref}>
        <div className="fordele">
          <h2>Hvorfor er SEO vigtigt?</h2>
          <p>SEO er afgørende for, at dine kunder kan finde din virksomhed, når de søger efter dine produkter eller tjenester online. Det er en længerevarende proces, der opbygger din synlighed i søgeresultaterne og hjælper med at tiltrække organisk trafik. Med SEO skaber du en stærk digital tilstedeværelse, der opbygger tillid hos brugerne og sikrer langsigtet vækst. Uden SEO risikerer din hjemmeside at drukne blandt konkurrenterne, hvilket gør det sværere for dig at nå dine målgrupper.</p>
        </div>
        <div className="fordele-grid">
          <div className="fordel-item">
            <div className="fordel-icon">
              <i className="fas fa-chart-line"></i>
            </div>
            <h3><span className="animated-number" data-target="75">0</span>%</h3>
            <p>af brugere klikker aldrig forbi den første side af søgeresultater</p>
          </div>
          <div className="fordel-item">
            <div className="fordel-icon">
              <i className="fas fa-bullseye"></i>
            </div>
            <h3><span className="animated-number" data-target="300">0</span>%</h3>
            <p>højere konverteringsrate for SEO-trafik sammenlignet med andre marketingkanaler</p>
          </div>
          <div className="fordel-item">
            <div className="fordel-icon">
              <i className="fas fa-mobile-alt"></i>
            </div>
            <h3><span className="animated-number" data-target="60">0</span>%</h3>
            <p>af alle søgninger kommer fra mobile enheder</p>
          </div>
          <div className="fordel-item">
            <div className="fordel-icon">
              <i className="fas fa-search-dollar"></i>
            </div>
            <h3><span className="animated-number" data-target="14">0</span>%</h3>
            <p>årlig vækst i organisk søgetrafik</p>
          </div>
        </div>
      </section>

      <section className="googleads-hero">
        <div className="googleads-hero-content">
          <h2>Boost din online synlighed med SEO-optimering</h2>
          <p className="googleads-hero-text">Vi hjælper din virksomhed med at forbedre placeringen i søgeresultaterne gennem ekspert-drevne løsninger inden for søgemaskineoptimering. Ved hjælp af både on-page og off-page optimering arbejder vi strategisk med relevante søgeord, mobiloptimering og backlinks for at demonstrere autoritet og sikre, at din hjemmeside rangerer højere end konkurrenterne. Dette øger trafikken og gør det lettere for kunder at finde din virksomhed online.</p>
          <div className="proces-afsnit-cta desktop-only">
            <CTAButton text="Kontakt os" navigateTo="/kontakt" />
          </div>
        </div>
        <div className="googleads-search-preview">
          <div className="search-bar">
            <input
              type="text"
              value={searchText}
              readOnly
              className="non-interactive"
              placeholder="Søg..."
            />
            <button className="search-button non-interactive">Søg</button>
          </div>
          <div className="search-results">
            <AnimatePresence>
              {showResults && visibleResults > 0 && (
                <motion.div
                  className="search-result seo-top-result"
                  variants={resultVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <h3>Din Virksomhed - Eksperter i [Dit Område]</h3>
                  <p className="result-url">www.dinvirksomhed.dk</p>
                  <p className="result-description">Førende eksperter i [Dit Område]. Kvalitet, innovation og kundetilfredshed er vores fokus. Kontakt os i dag for en uforpligtende snak.</p>
                </motion.div>
              )}
              {visibleResults > 1 && (
                <motion.div
                  className="search-result placeholder-result"
                  variants={resultVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <div className="placeholder-line"></div>
                  <div className="placeholder-line"></div>
                </motion.div>
              )}
              {visibleResults > 2 && (
                <motion.div
                  className="search-result placeholder-result"
                  variants={resultVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <div className="placeholder-line"></div>
                  <div className="placeholder-line"></div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only-seo">
          <CTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section className="customer-logos-section">
        <CustomerLogos />
      </section>

      <section className="proces-afsnit">
        <div className="proces-container">
          <div className="proces-title">
            <h2>Vores SEO-optimering</h2>
            <p>Vores SEO-proces er målrettet mod at forbedre din hjemmesides placering i søgeresultaterne ved at fokusere på både tekniske og indholdsmæssige optimeringer. Vi analyserer din hjemmesides nuværende performance, identificerer muligheder for forbedring og implementerer løsninger, der styrker både synlighed og brugeroplevelse. Gennem tæt samarbejde sikrer vi, at din hjemmeside ikke kun fungerer teknisk korrekt, men også appellerer til både søgemaskiner og dine besøgende.</p>
            <div className="proces-afsnit-cta desktop-only">
              <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
            </div>
          </div>
          <div className="proces-trin">
            <div className="trin">
              <h3>1. SEO-analyse</h3>
              <p>Vi gennemfører en grundig analyse af din hjemmeside og dine konkurrenter for at identificere forbedringsmuligheder.</p>
            </div>
            <div className="trin">
              <h3>2. Keyword-research</h3>
              <p>Vi finder de mest relevante og værdifulde søgeord for din branche og målgruppe.</p>
            </div>
            <div className="trin">
              <h3>3. On-page optimering</h3>
              <p>Vi optimerer din hjemmesides struktur, indhold og metadata for at forbedre dens relevans og autoritet.</p>
            </div>
            <div className="trin">
              <h3>4. Teknisk SEO</h3>
              <p>Vi sikrer, at din hjemmeside er teknisk optimeret for søgemaskiner, herunder hastighed, mobilvenlighed og indeksering.</p>
            </div>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only">
          <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section className="kundeafsnit">
        <CustomerTestimonials />
      </section>

      <CasesBlack />

      <section className="ekstra-services">
        <div className="ekstra-services-content">
          <h2>Få mere ud af din online tilstedeværelse</h2>
          <p>Udover SEO-optimering, som yderligere styrker din hjemmesides synlighed og performance, tilbyder vi også tjenester som Google Ads, AI-chatbots og webdesign. Disse løsninger arbejder sammen om at skabe en effektiv digital strategi, der tiltrækker relevante kunder og sikrer langsigtet vækst:</p>
        </div>
        <div className="services-grid">
          <Link to="/webdesign" className="service-item">
            <div className="service-icon">
              <i className="fas fa-laptop-code"></i>
            </div>
            <div className="service-text">
              <h3>Webdesign</h3>
              <p>Skræddersyede hjemmesider, der konverterer besøgende til kunder</p>
            </div>
          </Link>
          <Link to="/google-ads" className="service-item">
            <div className="service-icon">
              <i className="fab fa-google"></i>
            </div>
            <div className="service-text">
              <h3>Google Ads</h3>
              <p>Tiltræk kunder til din virksomhed gennem Google Ads. Vi sørger for, at dine annoncer når ud til de rigtige kunder og bidrager til vækst og resultater.</p>
            </div>
          </Link>
          <Link to="/ai-chatbot" className="service-item">
            <div className="service-icon">
              <i className="fas fa-robot"></i>
            </div>
            <div className="service-text">
              <h3>AI Chatbot</h3>
              <p>Intelligent kundesupport døgnet rundt med automatisering</p>
            </div>
          </Link>
        </div>
      </section>

      <section className="faq-section">
        <FAQ />
      </section>
    </div>
  );
}

export default SEO;